import React from "react"

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column" style={{ minHeight: "100vh" }}>
        <div className="warna-warni">
          <div className="py-5" />
          {children}
          <div className="py-5" />
          <div className="flex-grow-1" />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Layout
