import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"

const IndexPage = ({ data: { galleries }, data: { logo } }) => {
  let hutGalleries = []
  galleries.edges.map(({ node }) => {
    return hutGalleries.push(
      <div key={`${node.id}`} className="container-fluid">
        <h2 className="text-light text-center">{node.title}</h2>
        {node.fotovideo.map(media => {
          return (
            <div
              key={`${media.guid.id}`}
              className="position-relative w-100 mt-5"
              style={{ paddingTop: "56.62%" }}
            >
              <Img
                className="position-absolute w-100 rounded"
                style={{ top: "0", left: "0", height: "100%" }}
                fluid={media.guid.localFile.childImageSharp.fluid}
              />
            </div>
          )
        })}
      </div>
    )
  })
  return (
    <React.Fragment>
      <Layout>
        <div className="container-fluid text-center">
          <Img fixed={logo.childImageSharp.fixed} />
          <h1 className="display-4 text-light">
            HUT 24 Paroki Santa Monika BSD
          </h1>
          <div className="my-5 pb-5" />

          <div
            className="position-relative w-100"
            style={{ paddingTop: "56.25%" }}
          >
            <iframe
              className="position-absolute w-100"
              style={{ top: "0", left: "0", height: "100%" }}
              frameBorder="0"
              src="https://www.youtube.com/embed/RxTXJZCpu68"
            ></iframe>
          </div>
        </div>
        <div className="py-5" />
        {hutGalleries}
        <div className="container-fluid">
          <div className="py-5" />
          <p className="text-light text-center">
            {new Date().getFullYear().toString()} Ⓒ Santa Monika BSD
          </p>
          <p className="text-light text-center">
            All Documentation Rights goes to Documentation Team Santa Monika
          </p>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default IndexPage

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "hut24sanmon-icon.png" }) {
      childImageSharp {
        fixed(
          width: 300
          height: 300
          fit: CONTAIN
          background: "rgba(0,0,0,0)"
        ) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    galleries: allWordpressWpGaleriFotoHutSant {
      edges {
        node {
          id
          title
          fotovideo {
            guid {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
